var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-5 pa-4"},[_c('v-overlay',{attrs:{"value":_vm.load,"color":"white","absolute":"","opacity":".5"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('v-col',{staticClass:"d-flex flex-column pb-0",attrs:{"cols":"12"}},[_c('div',[_c('v-btn',{staticClass:"px-0 blue-grey--text",attrs:{"small":"","plain":""},on:{"click":_vm.toBack}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"18"}},[_vm._v("mdi-arrow-left")]),_vm._v(" Regresar ")],1)],1),_c('span',{staticClass:"text-h4 primary--text font-weight-bold mb-3"},[_vm._v("Actualizar Cliente")])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-6"},[_c('validation-observer',{ref:"UPDATE_CLIENT_FORM",attrs:{"tag":"div"}},[_c('v-row',[_c('v-col',{staticClass:"px-4",attrs:{"cols":"12","sm":"6","md":"7","lg":"7"}},[_c('span',{staticClass:"subtitle black--text font-weight-medium font-italic"},[_vm._v("Datos Principales")]),_c('v-card',{staticClass:"mt-3",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-text-box-check-outline")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("Nombre Comercial")])],1),_c('validation-provider',{attrs:{"name":"Nombre Comercial","vid":"datos.nombre_comercial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","menssages":_vm.client.nombre_comercial,"error-messages":errors[0]},model:{value:(_vm.datos.nombre_comercial),callback:function ($$v) {_vm.$set(_vm.datos, "nombre_comercial", $$v)},expression:"datos.nombre_comercial"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-text-box-check-outline")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("Razón Social")])],1),_c('validation-provider',{attrs:{"name":"Razón Social","vid":"datos.razon_social"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.client.razon_social,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.datos.razon_social),callback:function ($$v) {_vm.$set(_vm.datos, "razon_social", $$v)},expression:"datos.razon_social"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-text-box-check-outline")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("RIF")])],1),_c('validation-provider',{attrs:{"name":"RIF","vid":"datos.rif","rules":"required|integer|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"style-rif",attrs:{"value":_vm.client.rif,"outlined":"","dense":"","error-messages":errors[0]},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-select',{staticClass:"select-tr pa-0 ma-0 d-flex align-center",attrs:{"items":_vm.itemsRif,"hide-details":"","dense":""},model:{value:(_vm.tr),callback:function ($$v) {_vm.tr=$$v},expression:"tr"}})]},proxy:true}],null,true),model:{value:(_vm.datos.rif),callback:function ($$v) {_vm.$set(_vm.datos, "rif", $$v)},expression:"datos.rif"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-account-outline")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("Persona de Contácto")])],1),_c('validation-provider',{attrs:{"name":"Persona Contacto","vid":"datos.persona_contacto","rules":"alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.client.persona_contacto,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.datos.persona_contacto),callback:function ($$v) {_vm.$set(_vm.datos, "persona_contacto", $$v)},expression:"datos.persona_contacto"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-email-check-outline")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("Email")])],1),_c('validation-provider',{attrs:{"name":"Email","vid":"datos.correo","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.client.correo,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.datos.correo),callback:function ($$v) {_vm.$set(_vm.datos, "correo", $$v)},expression:"datos.correo"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-cellphone-iphone")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("Teléfono")])],1),_c('validation-provider',{attrs:{"name":"teléfono","vid":"datos.telefono","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.client.telefono,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.datos.telefono),callback:function ($$v) {_vm.$set(_vm.datos, "telefono", $$v)},expression:"datos.telefono"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-map-marker-outline")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("Latitud")])],1),_c('validation-provider',{attrs:{"name":"Latitud","vid":"datos.lat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.client.lat,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.datos.lat),callback:function ($$v) {_vm.$set(_vm.datos, "lat", $$v)},expression:"datos.lat"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"pb-1 d-flex align-center"},[_c('v-icon',{attrs:{"small":"","left":"","color":"blue-grey"}},[_vm._v("mdi-map-marker-outline")]),_c('span',{staticClass:"caption blue-grey--text text--darken-3"},[_vm._v("Longitud")])],1),_c('validation-provider',{attrs:{"name":"Longitud","vid":"datos.lng","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.client.lng,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.datos.lng),callback:function ($$v) {_vm.$set(_vm.datos, "lng", $$v)},expression:"datos.lng"}})]}}])})],1)],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"px-8",attrs:{"tile":"","depressed":"","color":"blue-grey lighten-5","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.toBack}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"pl-4 pr-5",attrs:{"tile":"","depressed":"","color":"primary","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.updateClient}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Actualizar ")],1)],1):_vm._e()],1)],1),_c('v-col',{staticClass:"blue-grey lighten-5 rounded-lg",attrs:{"cols":"12","sm":"6","md":"5","lg":"5"}},[_c('div',{style:(_vm.styleMap)},[_c('span',{staticClass:"subtitle black--text font-weight-medium font-italic"},[_vm._v("Geolocalización")]),_c('p',{staticClass:"caption blue-grey--text"},[_vm._v(" Seleccione en el Mapa la ubicacion del Cliente ")]),_c('gmap-map',{style:(_vm.styleMap),attrs:{"center":_vm.center,"zoom":15,"options":{
                    streetViewControl: false,
                    rotateControl: true,
                    disableDefaultUI: false,
                  }},on:{"click":_vm.getPosition}},[_c('gmap-marker',{attrs:{"position":_vm.markers.position,"clickable":true,"draggable":true,"icon":require('@/assets/Markers/marker_m1_verde.png')},on:{"click":function($event){_vm.center=_vm.markers.position}}})],1)],1)]),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-8",attrs:{"tile":"","depressed":"","color":"blue-grey lighten-5","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.toBack}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"pl-4 pr-5",attrs:{"tile":"","depressed":"","color":"primary","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.updateClient}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Actualizar ")],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }