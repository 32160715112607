<template>
  <v-row class="mb-5 pa-4">
    <v-overlay
      :value="load"
      color="white"
      absolute
      opacity=".5"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>
    <v-col cols="12" class="d-flex flex-column pb-0">
      <div>
        <v-btn 
          small 
          plain
          class="px-0 blue-grey--text"
          @click="toBack"
        >
          <v-icon size="18" class="mr-1">mdi-arrow-left</v-icon>
          Regresar
        </v-btn>
      </div>
      <span class="text-h4 primary--text font-weight-bold mb-3">Actualizar Cliente</span>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card>
        <v-card-text class="pa-6">
          <validation-observer ref="UPDATE_CLIENT_FORM" tag="div">
            <v-row>
              <v-col cols="12" sm="6" md="7" lg="7" class="px-4"> 
                <span class="subtitle black--text font-weight-medium font-italic">Datos Principales</span>
                <v-card
                  flat
                  tile
                  class="mt-3"
                >
                  <v-card-text class="px-0">                
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <div class="pb-1 d-flex align-center">
                          <v-icon small left color="blue-grey">mdi-text-box-check-outline</v-icon>
                          <span class="caption blue-grey--text text--darken-3">Nombre Comercial</span>
                        </div>
                        <validation-provider name="Nombre Comercial" vid="datos.nombre_comercial" rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.nombre_comercial"
                            outlined
                            dense
                            :menssages="client.nombre_comercial"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="7" class="py-0">
                        <div class="pb-1 d-flex align-center">
                          <v-icon small left color="blue-grey">mdi-text-box-check-outline</v-icon>
                          <span class="caption blue-grey--text text--darken-3">Razón Social</span>
                        </div>
                        <validation-provider name="Razón Social" vid="datos.razon_social" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.razon_social"
                            :value="client.razon_social"
                            outlined
                            dense
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="5" class="py-0">
                        <div class="pb-1 d-flex align-center">
                          <v-icon small left color="blue-grey">mdi-text-box-check-outline</v-icon>
                          <span class="caption blue-grey--text text--darken-3">RIF</span>
                        </div>
                        <validation-provider name="RIF" vid="datos.rif" rules="required|integer|max:9" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.rif"
                            :value="client.rif"
                            class="style-rif"
                            outlined
                            dense
                            :error-messages="errors[0]"
                          >
                            <template v-slot:prepend-inner>
                              <v-select
                                v-model="tr"
                                :items="itemsRif"
                                hide-details
                                dense
                                class="select-tr pa-0 ma-0 d-flex align-center"
                              />
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <div class="pb-1 d-flex align-center">
                            <v-icon small left color="blue-grey">mdi-account-outline</v-icon>              
                            <span class="caption blue-grey--text text--darken-3">Persona de Contácto</span>           
                        </div>
                        <validation-provider name="Persona Contacto" vid="datos.persona_contacto" rules="alpha_spaces" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.persona_contacto"
                            :value="client.persona_contacto"
                            outlined
                            dense
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>          
                      <v-col cols="12" md="6" class="py-0">
                        <div class="pb-1 d-flex align-center">
                          <v-icon small left color="blue-grey">mdi-email-check-outline</v-icon>
                          <span class="caption blue-grey--text text--darken-3">Email</span>
                        </div>
                        <validation-provider name="Email" vid="datos.correo" rules="email" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.correo"
                            :value="client.correo"
                            outlined
                            dense
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <div class="pb-1 d-flex align-center">
                            <v-icon small left color="blue-grey">mdi-cellphone-iphone</v-icon>              
                            <span class="caption blue-grey--text text--darken-3">Teléfono</span>           
                        </div>
                        <validation-provider name="teléfono" vid="datos.telefono" rules="integer" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.telefono"
                            :value="client.telefono"
                            outlined
                            dense
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <div class="pb-1 d-flex align-center">
                          <v-icon small left color="blue-grey">mdi-map-marker-outline</v-icon>
                          <span class="caption blue-grey--text text--darken-3">Latitud</span>
                        </div>
                        <validation-provider name="Latitud" vid="datos.lat" rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.lat"
                            :value="client.lat"
                            outlined
                            dense
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <div class="pb-1 d-flex align-center">
                            <v-icon small left color="blue-grey">mdi-map-marker-outline</v-icon>              
                            <span class="caption blue-grey--text text--darken-3">Longitud</span>           
                        </div>
                        <validation-provider name="Longitud" vid="datos.lng" rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="datos.lng"
                            :value="client.lng"
                            outlined
                            dense
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <!-- <v-col cols="12">
                        <span class="caption blue-grey--text">
                          Seleccione en el Mapa la ubicacion del Cliente
                        </span>
                      </v-col> -->
                    </v-row>                
                  </v-card-text>
                  <v-card-actions v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-space-between">
                    <v-btn
                      tile
                      depressed
                      color="blue-grey lighten-5"
                      class="px-8"
                      :small="$vuetify.breakpoint.mobile"
                      @click="toBack"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      tile
                      depressed
                      color="primary"
                      class="pl-4 pr-5"
                      :small="$vuetify.breakpoint.mobile"
                      @click="updateClient"
                    >
                      <v-icon dark left>mdi-pencil</v-icon>
                      Actualizar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="5"
                lg="5"
                class="blue-grey lighten-5 rounded-lg"
              >
                <div
                  class=""
                  :style="styleMap"
                >              
                  <span class="subtitle black--text font-weight-medium font-italic">Geolocalización</span>
                  <p class="caption blue-grey--text">
                    Seleccione en el Mapa la ubicacion del Cliente
                  </p>
                  <gmap-map
                    :center="center"
                    :zoom="15"
                    :options="{
                      streetViewControl: false,
                      rotateControl: true,
                      disableDefaultUI: false,
                    }"
                    :style="styleMap"
                    @click="getPosition"
                  >
                    <gmap-marker
                      :position="markers.position"
                      :clickable="true"
                      :draggable="true"
                      @click="center=markers.position"
                      :icon="require('@/assets/Markers/marker_m1_verde.png')"
                    />                    
                  </gmap-map>
                </div>
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.xsOnly"
                cols="12"
                class="d-flex justify-space-between"
              >
                <v-btn
                  tile
                  depressed
                  color="blue-grey lighten-5"
                  class="px-8"
                  :small="$vuetify.breakpoint.mobile"
                  @click="toBack"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  tile
                  depressed
                  color="primary"
                  class="pl-4 pr-5"
                  :small="$vuetify.breakpoint.mobile"
                  @click="updateClient"
                >
                  <v-icon dark left>mdi-pencil</v-icon>
                  Actualizar
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

import { updateInfoClient } from '@/services/clientes-potenciales.js'
const clientDefault = () => ({
  razon_social: '',
  nombre_comercial: '',
  direccion: '',
  persona_contacto: '',
  correo: '',
  rif: '',
  telefono: '',
  lat: null,
  lng: null,
})

const getTypeRif = (rif) => {
  const firstRif = rif.at(0)
  return firstRif === 'J' || firstRif === 'G' 
    ? firstRif
    : 'J'
}
export default {
  name: 'UpdateClient',
  props: {
    client: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    load: false,
    datos: clientDefault(),
    center: { lat: 8.955582, lng: -65.3220065 },
    markers: {},
    itemsRif: ['J', 'G'],
    tr: 'J',
  }),
  watch: {
    client (val) {
      if (Object.values(val).length > 0) {
        this.datos = { ...val, rif: val !== null ? val.rif.slice(1) : '' }
        this.tr = val.rif !== null ? getTypeRif(val.rif) : 'J'
      } else {
        this.datos = clientDefault()
      }
    },
  },
  created () {
    if (Object.values(this.client).length > 0) {
      this.datos = { ...this.client, rif: this.client.rif.slice(1) }
      this.tr = this.client.rif !== null ? getTypeRif(this.client.rif) : 'J'
    } else {
      this.datos = clientDefault()
    }
  },
  mounted () {
    if(this.datos.lat != null && this.datos.lng != null) {
      this.center = {
        lat: this.datos.lat ? parseFloat(this.datos.lat.trim()) : null,
        lng: this.datos.lng ? parseFloat(this.datos.lng.trim()) : null,
      };
      this.markers = { position: this.center };
    }
    else
      this.geolocate();
  },
  methods: {
    async updateClient () {
      const valid = await this.$refs.UPDATE_CLIENT_FORM.validate();
      if (valid) {
        this.load = true
        try {
          const { message } = await updateInfoClient({
            id: this.client.co_cli_po, 
            datos: { ...this.datos, rif: this.tr + this.datos.rif }, 
          })
          this.$root.$showAlert(message, 'success');
          this.$emit('updated')
        } catch (error) {
          this.$root.$showAlert(
            'Lo sentimos, hubo un error al intentar realizar la actualizacion del Cliente.',
            'error',
          );
        } finally {
          this.load = false
        }
      }
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    },
    getPosition (e) {
      this.markers = { position: {lat: e.latLng.lat(), lng: e.latLng.lng() } };
      this.datos.lat = e.latLng.lat();
      this.datos.lng = e.latLng.lng();
    },
    toBack () {
      this.$emit('back')
    },
  },
  computed: {
    styleMap () {
      return {
        width: '100%',
        height: this.$vuetify.breakpoint.mobile ? '100vh' : '100%',
        'margin-right': '12px',
        overflow: 'hidden',
      }
    }
  },
}
</script>
<style>
.container-geo {
  overflow: hidden;
}
.select-tr.v-text-field > .v-input__control > .v-input__slot:before, .select-tr.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}

.select-tr.v-text-field > .v-input__control {
  width: 38px;
}

.select-tr.v-text-field > .v-input__control .v-input__icon {
  width: 14px;
  min-width: 14px;
}

.select-tr.v-select.v-input--dense .v-select__selection--comma {
  margin: 0 !important;
}

.select-tr.v-text-field > .v-input__control .v-input__append-inner {
  padding: 0 5px !important;
  margin-top: 2px !important;
}

.select-tr.v-text-field > .v-input__control .v-input__icon {
  width: 14px;
  min-width: 14px;
}

.style-rif.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {
  margin-top: 0 !important;
  border-color: rgba(0, 0, 0, 0.18);
  border-style: solid;
  border-width: 0 1px 0 0;
  margin-right: 10px;
  padding: 0;
  min-height: 39px !important;
}

.select-tr.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
   margin-top: 0 !important;
}
</style>
